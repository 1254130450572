<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="$t('Components.Generic.back')"
      :title="resourceName + ' - ' + $t('Views.Resource.Issue.header')"
    >
      <h5 class="header-title">
        {{ $t('Views.Resource.Issue.header') }}
      </h5>
    </Header>
    <div class="scrollable">
      <!-- Equipment Selection -->
      <Card>
        <SubHeader>
          <h5 class="margin margin-bottom margin-medium-large">
            {{ $t('Views.Resource.Information.equipment') }}
          </h5>
        </SubHeader>

        <div>
          <IssueTypeList
            :zoneid="zid"
            :issue-type-list="zoneEquipments"
            :clickable="true"
            @issue-type-clicked="onIssueTypeClicked"
          />
        </div>
      </Card>

      <!-- Text Area : Description -->
      <Card position="bottom">
        <h5 class="margin margin-bottom margin-medium-large">
          {{ $t('Views.Resource.Issue.description') }}
        </h5>
        <textarea
          v-model="issue.description"
          class="margin margin-medium-large margin-bottom form-control"
          :placeholder="$t('Views.Resource.Issue.text_area_placeholder')"
        />

        <!-- Submit Button -->
        <Button
          :disabled="!canSubmit"
          class="issue_submit_button_container margin margin-top"
          variant="primary"
          @click="submit()"
        >
          {{ $t('Views.Resource.Issue.submit') }}
        </Button>
      </Card>
    </div>
  </div>
</template>

<script>
/** States */
import CompanyState from '@/singletons/company.state.singleton';
import UserState from '@/singletons/user.state.singleton';

/** Components */
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import SubHeader from '@/components/headers/sub.header.vue';
import Button from '@/components/button/button.vue';
import IssueTypeList from '@/components/issue/issue.type.list.vue';

/** Services */
import CompanyService from '@/Services/Company/company.service';

/** Classes */
import CustomEvent from '@/classes/custom.event.class';
import IssueService from '@/Services/Issue/issue.service';
import MapController from '@/classes/map/map.controller';
import { Issue } from '@/classes/issue/issue';
import ExtendedDate from '@/classes/extended.date.class';

export default {
  components: {
    Header,
    Card,
    SubHeader,
    IssueTypeList,
    Button,
  },
  data() {
    return {
      issue: new Issue(),
      zoneEquipments: [],
      description: null,
      zid: null,
      tech_support: null,
      facility_manager: null,
    };
  },
  computed: {
    canSubmit() { return this.issue.description; },
    resourceName() { return CompanyState.zones.find((zone) => zone.Zid === this.zid).Name; },
  },
  async beforeMount() {
    const { zid } = this.$route.params;
    this.zid = parseInt(zid, 10);
    this.zone = await CompanyService.getCachedZone(this.zid);
    this.floor = await CompanyService.getCachedZone(this.zone.ParentZone);
    this.tech_support = this.floor.Properties.tech_support;
    this.facility_manager = this.floor.Properties.facility_manager;
    this.zoneEquipments = await this.getEquipmentForZone(this.zid);
  },
  methods: {
    async getEquipmentForZone(zid) {
      const eqs = this.zone?.Equipment || (await CompanyService.getCachedZone(zid)).Equipment || [];
      return eqs.map((eq) => Object.assign(eq, { Selected: false, Recipient: this.facility_manager }));
    },
    onIssueTypeClicked(issueType) {
      // eslint-disable-next-line no-param-reassign
      issueType.Selected = !issueType.Selected;
      const newArr = this.zoneEquipments.map((el) => el);
      this.$set(this, 'zoneEquipments', newArr);
    },
    getSelectedIssueTypes() {
      return this.zoneEquipments.filter((eq) => eq.Selected);
    },
    async submit() {
      const issueTypes = [...this.getSelectedIssueTypes().map((issueType) => ({ type: issueType.Title, icon: issueType.Icon }))];
      const equipments = this.getSelectedIssueTypes().map((issueType) => issueType.Title);
      const recipients = [...new Set(this.getSelectedIssueTypes().map((eq) => eq.Recipient))];

      this.issue.authorid = UserState.userId;
      this.issue.floorid = this.zone?.ParentZone || MapController?.getFloor();
      this.issue.zoneid = this.zone?.Zid;
      this.issue.companyid = CompanyState.cid;
      this.issue.types = issueTypes.length ? issueTypes : null;
      this.issue.pinned = false;

      const sendGrid = {
        recipients: recipients.length ? recipients : [this.facility_manager],
        data: {
          location: CompanyService.getLocationByParent(this.zone.Parent, this.zone.Zid),
          resourceName: this.resourceName,
          date: new ExtendedDate().localeTimeString(),
          link: `${window.location.origin}/book/${this.$route.params.type}/${this.$route.params.zid}/information`,
          issuer: UserState.username,
          issuerName: UserState.profile.Name,
          description: this.issue.description,
          equipment: equipments.length ? equipments : null,
        },
      };

      try {
        await IssueService.createIssue(this.issue, sendGrid);
        new CustomEvent(this.$t('Views.Book.issue_report_sent'), 'global_error_message', 'success').dispatch();
        this.$router.go(-1);
      } catch (e) {
        new CustomEvent(this.$t('Views.Book.error_occurred')).dispatch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.issue_submit_button_container{
    display: flex;
    justify-content: space-around;
    margin: 1rem auto;
}

textarea {
  min-height: 6.0rem;
}

</style>
